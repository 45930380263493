import { useQuery } from "@apollo/client";
import * as React from "react";
import { Link } from "react-router-dom";
import { generate } from "shortid";
import {
    GET_FOOTER_QUERY,
    GET_NAVIGATION,
    DynamicLink,
    getFetchPolicy,
    showLoad,
    NavigationData,
} from "@plinknz/tah-website-elements";
import { Copyright } from "../components/copyright";
import {
    NGĀTI_KUIA_FACEBOOK,
    NGĀTI_KUIA_YOUTUBE,
    NGĀTI_KUIA_INSTAGRAM,
} from "../config/external";

export interface FooterData {
    footer: FooterType;
}

interface FooterType {
    items: FooterNavigationItem[];
}

export interface FooterNavigationItem {
    mainpage?: FooterNavigationLink;
    pages: FooterNavigationLink[];
}

export interface FooterNavigationLink {
    title: string;
    slug: string;
    icon?: string;
}

export const Footer = () => {
    const key = generate();
    const { data, loading, error } = useQuery<FooterData>(GET_FOOTER_QUERY, {
        fetchPolicy: getFetchPolicy(),
    });
    const { data: navData } = useQuery<NavigationData>(GET_NAVIGATION, {
        fetchPolicy: getFetchPolicy(),
    });
    const renderNavigationLink = ({
        title,
        slug,
        icon,
    }: FooterNavigationLink) => {
        return (
            <li key={generate()} className="footer-navigation-item">
                <DynamicLink to={slug} className="footer-link">
                    {icon && (
                        <>
                            <i className={icon} />{" "}
                        </>
                    )}
                    {title}
                </DynamicLink>
            </li>
        );
    };
    const renderNavigationItem = ({
        mainpage,
        pages,
    }: FooterNavigationItem) => (
        <div className="footer-navigation-set" key={generate()}>
            {mainpage && (
                <Link
                    to={mainpage.slug}
                    className="footer-link footer-navigation-title">
                    {mainpage.title}
                </Link>
            )}
            <ul className="footer-navigation-list">
                {pages?.map(renderNavigationLink)}
            </ul>
        </div>
    );
    interface AddressProps {
        title: string;
        subtitle?: string;
        phoneNumbers: { text?: string; num: string }[];
        address: React.ReactNode;
        openingTimes?: string;
        email: string;
        map: string;
    }
    const Address = ({
        title,
        subtitle,
        phoneNumbers,
        address,
        openingTimes,
        email,
        map,
    }: AddressProps) => (
        <div className="footer-sections">
            <address className="footer-address" key={key}>
                <p className="footer-place-text">{title}</p>

                <p className="footer-place-text">{subtitle}</p>

                <div className="footer-numbers" key={key}>
                    {phoneNumbers.map(({ text, num }) => (
                        <p className="footer-text" key={generate()}>
                            <a className="footer-phone" href={`tel:${num}`}>
                                {text || num}
                            </a>
                        </p>
                    ))}
                </div>
                <p className="footer-text">{openingTimes}</p>

                <p className="footer-text">
                    <a
                        className="footer-text"
                        href={map}
                        target="_blank"
                        rel="noreferrer">
                        {address}
                    </a>
                </p>
                <a
                    className="footer-text"
                    href={map}
                    target="_blank"
                    rel="noreferrer">
                    <div
                        className="footer-gmap footer-gmap-inflate"
                        style={{
                            backgroundImage: 'url("/icons/gmap.png")',
                        }}
                    />
                </a>
                <p className="footer-text">
                    <a
                        className="footer-text"
                        href={`mailto:${email}?subject=Inquiries from Ngāti Kuia website`}
                        target="_blank"
                        rel="noreferrer">
                        {email}
                    </a>
                </p>
            </address>
        </div>
    );
    const socialIcons = () => (
        <>
            <div>
                <p className="footer-navigation-title">Connect With Us</p>

                <div className="footer-social">
                    <a
                        href={NGĀTI_KUIA_FACEBOOK}
                        target="_blank"
                        rel="noreferrer">
                        <i className="fab fa-facebook-square footer-facebook footer-icon-inflate" />
                    </a>
                    <a
                        href={NGĀTI_KUIA_FACEBOOK}
                        className="footer-links"
                        target="_blank"
                        rel="noreferrer">
                        Facebook
                    </a>
                </div>
                <div className="footer-social">
                    <a
                        href={NGĀTI_KUIA_YOUTUBE}
                        target="_blank"
                        rel="noreferrer">
                        <i className="fab fa-youtube-square footer-youtube footer-icon-inflate" />
                    </a>
                    <a
                        href={NGĀTI_KUIA_YOUTUBE}
                        className="footer-links"
                        target="_blank"
                        rel="noreferrer">
                        YouTube
                    </a>
                </div>
                <div>
                    <a
                        href={NGĀTI_KUIA_INSTAGRAM}
                        target="_blank"
                        rel="noreferrer">
                        <i className="fab fa-instagram-square footer-instagram footer-icon-inflate" />
                    </a>
                    <a
                        href={NGĀTI_KUIA_INSTAGRAM}
                        className="footer-links"
                        target="_blank"
                        rel="noreferrer">
                        Instagram
                    </a>
                </div>
            </div>
        </>
    );

    if (showLoad(loading)) {
        return null;
    }

    return (
        <div className="footer">
            <div className="footer-layer secondary">
                <div className="footer-inner || constrain-width">
                    <div className="footer-logo" />
                    <Address
                        title="Nelson Office"
                        subtitle="Te Tari o Whakatū"
                        phoneNumbers={[
                            {
                                num: "0800 642 845",
                                text: "0800 NGĀTI KUIA ( 0800 642 845 )",
                            },
                            { num: "03 546 7556" },
                        ]}
                        openingTimes="8:30am - 5pm Monday to Friday"
                        address={
                            <>
                                192 Rutherford Street <br />
                                PO Box 968 <br />
                                Nelson 7040
                            </>
                        }
                        email="tari@ngatikuia.iwi.nz"
                        map="https://www.google.com/maps/place/Te+Runanga+O+Ngati+Kuia/@-41.2778812,173.2804265,15z/data=!4m5!3m4!1s0x0:0x9e8f313945c1e14!8m2!3d-41.2779375!4d173.2804269"
                    />

                    <Address
                        title="Blenheim Office"
                        subtitle="Te Tari o Wairau"
                        phoneNumbers={[{ num: "03 579 4328" }]}
                        openingTimes="8:30am - 5pm Monday to Friday"
                        address={
                            <>
                                19 Kinross Street <br />
                                PO Box 1046 <br />
                                Blenheim 7240
                            </>
                        }
                        email="tariwairau@ngatikuia.iwi.nz"
                        map="https://www.google.com/maps/place/Te+Runanga+O+Ngati+Kuia+Trust/@-41.5148172,173.9584557,15z/data=!4m5!3m4!1s0x0:0xecea2954fa35595!8m2!3d-41.5148363!4d173.9584578"
                    />
                    <Address
                        title="Te Hora Marae"
                        phoneNumbers={[
                            { num: "03 574 2034" },
                            { num: "027 288 7809" },
                        ]}
                        address={
                            <>
                                Te Hora Pa Road <br />
                                Canvastown <br />
                                Marlborough
                            </>
                        }
                        email="tehora@ngatikuia.iwi.nz"
                        map="https://www.google.com/maps/place/Te+Hora+Pa/@-41.2890881,173.6645398,17z/data=!3m1!4b1!4m5!3m4!1s0x6d395968693506fb:0xee522da7074ea5ff!8m2!3d-41.2890881!4d173.6667285"
                    />
                </div>
            </div>

            {navData?.navigation?.action && (
                <div className="footer-layer secondary">
                    <div className="footer-inner || constrain-width">
                        <div className="footer-sections">
                            <Link
                                to={navData.navigation.action.link.slug}
                                className="button large secondary">
                                {navData.navigation.action.label.toUpperCase()}
                            </Link>
                            <p className="heading-5">
                                Register with Ngāti Kuia
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <footer className="footer-layer divider">
                <div className="footer-inner logo || constrain-width">
                    <div className="footer-sections">
                        <div className="footer-section footer-navigation">
                            {!error &&
                                data?.footer?.items.map(renderNavigationItem)}
                        </div>

                        <div className="footer-section">{socialIcons()}</div>
                    </div>

                    <Copyright />
                </div>
            </footer>
        </div>
    );
};
