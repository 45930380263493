import { useQuery } from "@apollo/client";
import {
    classNames,
    getFetchPolicy,
    GET_NAVIGATION,
    NavigationData,
    Page,
    showLoad,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import { NavLink, useHistory } from "react-router-dom";

export const Navigation: React.FunctionComponent = () => {
    const { data, loading, error } = useQuery<NavigationData>(GET_NAVIGATION, {
        fetchPolicy: getFetchPolicy(),
    });
    const [isOpen, toggleOpen] = React.useState(false);
    const [activeParent, setActiveParent] = React.useState<string>(null);
    const history = useHistory();
    const navClass = classNames("navigation", {
        "is-active": isOpen,
    });

    const renderItem = (page: Page, cta = false) => {
        const hasChildPages = page.children?.length > 0;
        const itemIsOpen = activeParent === page.slug;
        const itemClass = classNames("navigation-item", {
            "is-open": itemIsOpen,
            "navigation-link button secondary cta": cta,
        });
        const slug = page.slug === "home" ? "/" : page.slug;
        const childClass = classNames("navigation-item-children");

        if (cta) {
            return (
                <li className={itemClass}>
                    <NavLink
                        to={slug}
                        activeClassName="is-active"
                        className="navigation-link">
                        {page.title}
                    </NavLink>
                </li>
            );
        }
        return (
            <li className={itemClass} key={page.slug}>
                <div className="navigation-item-inner">
                    <NavLink
                        to={slug}
                        activeClassName="is-active"
                        className="navigation-link">
                        {page.title}
                    </NavLink>
                    {hasChildPages && (
                        <button
                            className="navigation-item-toggle"
                            title={itemIsOpen ? "Show less" : "Show more"}
                            type="button"
                            onClick={(event) => {
                                event.stopPropagation();
                                setActiveParent(itemIsOpen ? null : slug);
                            }}>
                            <i className="icon || fas fa-angle-down" />
                        </button>
                    )}
                </div>

                {hasChildPages && (
                    <div className={childClass}>
                        {page.children.map((childPage) => (
                            <NavLink
                                key={childPage.slug}
                                to={childPage.slug}
                                className="navigation-link">
                                {childPage.title}
                            </NavLink>
                        ))}
                    </div>
                )}
            </li>
        );
    };

    React.useEffect(() => {
        document.body.classList.toggle("navigation-open", isOpen);
        document.querySelector("html").style.overflowY = isOpen
            ? "hidden"
            : "auto";

        return history.listen(() => toggleOpen(false));
    }, [isOpen]);

    if (error) {
        console.error(error);

        return null;
    }

    if (showLoad(loading)) {
        return null;
    }

    return (
        <nav className={navClass} role="navigation" data-testid="navigation">
            <div
                className="navigation-toggle"
                role="button"
                tabIndex={0}
                onKeyDown={(e) =>
                    e.key === "Enter" ? toggleOpen(!isOpen) : null
                }
                onClick={() => toggleOpen(!isOpen)}>
                <div className="hamburger">
                    <div className="hamburger-wrapper">
                        <div className="hamburger-item line-1" />
                        <div className="hamburger-item line-2" />
                        <div className="hamburger-item line-3" />
                    </div>
                </div>
            </div>
            <div className="navigation-inner">
                <ul className="navigation-list">
                    {data?.navigation?.link_to_page.map(({ page }) =>
                        renderItem(page)
                    )}
                    {data?.navigation?.action &&
                        renderItem(
                            {
                                slug: data.navigation.action.link
                                    .slug as string,
                                title: data.navigation.action.label,
                            },
                            true
                        )}
                </ul>
            </div>
        </nav>
    );
};
