import { classNames } from "@plinknz/tah-website-elements";
import React, { useEffect, useState } from "react";
import Headroom from "react-headroom";
import { Link } from "react-router-dom";
import { Navigation } from "./navigation";

export interface HeaderProps {
    enableNavigation?: boolean;
}

export const Header = ({ enableNavigation = true }: HeaderProps) => {
    const [colorChange, setColorChange] = useState(false);
    const changeNavbarColor = () => setColorChange(window?.scrollY >= 80);
    const headerClass = classNames("headroom", {
        colorChange,
    });

    useEffect(() => {
        window?.addEventListener("scroll", changeNavbarColor);

        return () => window?.removeEventListener("scroll", changeNavbarColor);
    }, []);

    return (
        <Headroom>
            <header className={headerClass} role="banner">
                <div className="header-secondary">
                    <div className="header-secondary-inner || constrain-width large" />
                </div>
                <div className="header-inner || constrain-width large">
                    <Link to="/" className="header-logo">
                        Ngāti Kuia
                    </Link>
                    <h1 className="header-title">
                        <Link className="header-title-link" to="/">
                            Ngāti Kuia
                        </Link>
                    </h1>
                    {enableNavigation && <Navigation />}
                </div>
            </header>
        </Headroom>
    );
};
